<template>

</template>

<script>
import {initRoute} from "../router/initRoute";
import {resetRouter} from '@/router/index.js'
import { setCookie } from "@/utils/utils";

export default {
  name: "callback",
  mounted(){
    resetRouter()
    this.getAuthorize()
  },
  methods:{
    getAuthorize(){
      const {bloc,token,code} = this.$route.query;
      let param = {
        bloc,
        marketCode:code,
        tokenFromPortal:token
      };
      this.$api.sysapi.getAuth(param).then(({ code, data, msg }) => {
        if (code === 200) {
          if(!data.sysModule){
            return this.$message.error('当前用户没有访问权限，请联系管理员')
          }
          var exp = new Date()
          var Days = 7
          exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
          document.cookie = `DK_TOKEN=${data.token
          };expires=${exp.toGMTString()}`
          localStorage.setItem('sysModule', JSON.stringify(data.sysModule))
          let permission = data.sysModule.child.filter(item => item.id === 2);
          let pathName
          if(permission&&permission.length){   //  有后台权限
            const _menuList = initRoute(data.sysModule)
            delete data.sysModule
            localStorage.setItem('userInfo', JSON.stringify(data))
            localStorage.setItem('menuList', JSON.stringify(_menuList))
            pathName = _menuList.filter(e=>e.meta.isShow)[0].path;
            this.$router.push({ path: pathName })
          }else{   //  无权限
            this.$message.error('当前用户没有任何权限，请联系管理员分配权限')
          }
        } else {
          this.$message.error(msg)
        }
      }).catch(err => {
      })
    }
  }
}
</script>

<style scoped>

</style>